.custom-accordion {
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--bs-scrollbar-color) !important;
    color: var(--bs-text-dark) !important;
    font-weight: bold;
    font-size: 1.2rem !important;
  }
  
.custom-accordion-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.custom-accordion-details {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.agentes-title{
    margin-top: 1rem;
    font-size: 1.4rem !important;
    font-weight: bold;
}

.agente-card{
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--bs-scrollbar-color) !important;
}

.agentes-card-title{
    font-size: 1.3rem !important;
    font-weight: bold;
}

.agentes-card-subtitle{
    font-size: 1.1rem !important;
    font-weight: bold;
}

.agentes-card-text{
    font-size: 1rem !important;
    font-weight: bold;
}

.botao-margin{
    margin-right: 0;
}

.btn-icon i {
    font-size: 30px;
}

.botao-filtro{
    color: red;
    margin-left: 2px;
}

@media (min-width: 768px) {
    .margin-button{
        margin-top: 2rem;
        margin-right: 2rem;
    }
}

@media (max-width: 768px) {
    .button-lista-card{
        margin-left: 3rem !important;
    }
}


.selecionar-arquivos{
    z-index: 10000;
}

.btn-remove-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 20%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.btn-remove-list:hover {
    background-color: darkred;
  }

.btn-install-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 20%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.btn-install-list:hover {
    background-color: darkgreen;
  }
  
.modal-custom .modal-dialog {
    max-width: 90vw; /* 90% da largura da tela */
    max-height: 80vh; /* 80% da altura da tela */
  }
  