.botao-espaco {
    margin-top: 25rem !important; /* Adiciona espaço acima do título */
}

.tamanho-titulo {
    min-height: 60px; /* Garante altura mínima para alinhamento */
    max-height: 80px; /* Evita que o título fique muito grande */
    height: 500px;
    display: flex;
    align-items: center; /* Alinha verticalmente o texto */
    justify-content: center;
    text-align: center;
    white-space: normal; /* Permite que o texto quebre corretamente */
    word-wrap: break-word;
}

.fonte-titulo{
    font-family: 'Argumentum';
    src: url('../../public/media/debrum/Argumentum\ Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.fonte-botao{
    font-family: 'Argumentum';
    src: url('../../public/media/debrum/Argumentum\ Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.d-flex.flex-wrap.justify-content-center {
    align-items: stretch !important; /* Garante alinhamento uniforme */
}

.image-landing{
    max-width: 500px;
    max-height: 666px;
    object-fit: cover;
}

@media (min-width: 768px) {
    .carousel-espaco-imagem {
        margin-left: 5rem !important; /* Adiciona espaço acima do título */
    }
}

@media (max-width: 768px) {
    .carousel-espaco {
        margin-top: 15rem !important; /* Adiciona espaço acima do título */
    }

    .tamanho-titulo{
        height: auto;
    }

    .botao-espaco {
        margin-top: 14rem !important; /* Adiciona espaço acima do título */
    }

    .image-landing{
        max-width: 500px;
        max-height: 520px;
        object-fit: cover;
    }
}

@media (max-width: 400px) {
    .carousel-espaco {
        margin-top: 10rem !important; /* Adiciona espaço acima do título */
    }

    .tamanho-titulo{
        height: auto;
    }

    .botao-espaco {
        margin-top: 10rem !important; /* Adiciona espaço acima do título */
    }

    .image-landing{
        max-width: 500px;
        max-height: 480px;
        object-fit: cover;
    }
}

@media (max-width: 950px) and (min-width: 768px) {
    .carousel-espaco {
        margin-top: 20rem !important; /* Adiciona espaço acima do título */
    }

    .tamanho-titulo{
        height: auto;
    }

    .botao-espaco {
        margin-top: 23rem !important; /* Adiciona espaço acima do título */
    }
}

.custom-indicators {
    position: absolute;
    bottom: -20px; /* Ajuste conforme necessário */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.custom-indicators button {
    width: 12px;
    height: 12px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: #000 !important;
    color: #000 !important;
    border: none;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.custom-carousel .carousel-indicators {
    position: absolute;
    bottom: -30px; /* Ajuste este valor conforme necessário */
    left: 36%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.carousel-indicators [data-bs-target]{
    background-color: #000000 !important;
}

.carousel-control-next-icon{
    color: #000 !important;
    scrollbar-color: #000 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon{
    color: #000 !important;
    scrollbar-color: #000 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon, [data-bs-theme=dark] .carousel .carousel-control-next-icon {
    filter: none !important;
}

.custom-carousel .carousel-indicators li {
    background-color: #000 !important;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.botao-espaco button {
    background-color: #222221; /* Cor do fundo */
    border-color: #222221; /* Cor da borda */
    color: #fff; /* Cor do texto */
    font-weight: normal; /* Peso do texto padrão */
    transition: font-weight 0.3s ease; /* Suaviza a transição do peso da fonte */
}

.botao-espaco button:hover {
    color: #fff; /* Mantém a cor do texto */
    font-weight: bold; /* Deixa o texto em negrito no hover */
    background-color: #222221 !important; /* Mantém a cor de fundo */
    border-color: #222221 !important; /* Mantém a cor da borda */
}