.chat-title-input {
    font-size: 14px; /* Aumenta a fonte para melhor leitura */
    width: 100%; /* Ajusta automaticamente ao conteúdo */
    max-width: 150px; /* Permite um pouco mais de espaço */
    padding: 5px 8px; /* Adiciona espaço interno */
    border: 1px solid #ccc; /* Borda discreta */
    border-radius: 5px; /* Deixa os cantos arredondados */
    outline: none; /* Remove a borda azul padrão */
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
}

.chat-content-agente {
    display: flex;
    flex-direction: column;
    flex-grow: 1;  /* Isso faz a área de conteúdo crescer para ocupar o máximo de espaço */
    overflow-y: auto;
    width: 100%;
    padding: 1rem;
}

.messages-agente {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    overflow-y: auto;
    flex-grow: 1;
    max-height: 70vh;
}

.message {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    margin: 0;
    word-wrap: break-word;  /* Permite a quebra de linha quando o texto é longo */
    white-space: normal;    /* Faz com que o conteúdo de texto se quebre */
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

.user-container {
    align-items: flex-end !important;
}

.message-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.avatar-user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
}

.sender-name {
    font-weight: bold;
    margin-right: 5px;
}


.sender-name-user {
    font-weight: bold;
    margin-left: 5px;
}

.timestamp {
    font-size: 12px;
    color: gray;
}

.timestamp-user {
    font-size: 12px;
    color: gray;
}

.user-message {
    background-color: #575757;
    color: white;
    align-self: flex-end; /* Mensagem do usuário alinhada à direita */
}

.chat-message {
    background-color: #ddd;
    color: black;
    align-self: flex-start; /* Mensagem do chat alinhada à esquerda */
}

.message-input {
    position: relative; 
    width: 100%;
    margin-top: 10px;
    padding: 0;
}

.message-input input {
    width: 100%;
    padding: 10px 50px 10px 10px; /* Espaço para o botão no lado direito */
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box; /* Garante que padding não afete o tamanho */
}

.message-input button {
    position: absolute; /* Coloca o botão dentro do contêiner */
    top: 50%; /* Centraliza verticalmente */
    right: 10px; /* Alinha à direita dentro do input */
    transform: translateY(-50%); /* Ajusta para o centro exato */
    background-color: white;
    color: #222220;
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.bi-arrow-bar-up {
    font-size: 20px; /* Ajuste o tamanho conforme necessário */
    font-weight: bold; /* Torna o ícone mais "grosso" */
    color: #222220; /* Cor do ícone */
}

.chat-placeholder {
    text-align: center;
    color: #888;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message-input.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.message-input.centered input {
    margin-bottom: 10px;
    width: 100%;
    border-radius: 5px;
}

.loading-dots {
    display: flex;
    justify-content: start;
    margin: 1rem 0;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #9b9b9b;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10px);
    }
}

/*RECORDING*/
.recording-indicator-container {
    display: flex;
    align-items: center;
}

.recording-indicator {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: red;
    animation: fade-in-out 1.5s infinite;
}

.recording-dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
}

@keyframes fade-in-out {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}