/*Inicio css table */
.table-pro4tech-border{
  border: 1px solid #0e7adb !important;
  border-radius: 5px !important;
}

.bg-pro4tech{
  background: #0e7adb !important;
  color: #fcfcfc !important;
}

.border-pro4tech{
  border-color: #0e7adb !important;
}

.table-pro4tech-header{
  background: #0e7adb !important;
  color: #fcfcfc !important;
}

.nav-pro4tech.nav-link.active {
  border-bottom: 2px solid #0e7adb !important;
}

.nav-pro4tech.nav-link:hover {
  border-bottom: 2px solid #0e7adb !important;
}

.table-pro4tech-footer{
  background: #0e7adb !important;
  color: #fcfcfc !important;
}

[data-bs-theme="light"] .table-pro4tech-row{
  background-color: #fdfdfd !important;
}

[data-bs-theme="dark"] .table-pro4tech-row{
  background-color: #171b26 !important;
}

[data-bs-theme="light"] .table-pro4tech-row:hover{
  background-color: #eeeeee !important;
}

[data-bs-theme="dark"] .table-pro4tech-row:hover{
  background-color: #2c3241 !important;
}

[data-bs-theme="light"] .table-pro4tech-cell{
  color: #2b2b2b !important;
  border-color: #0e7adb !important;
}

[data-bs-theme="dark"] .table-pro4tech-cell{
  color: #e4e4e4 !important;
  border-color: #0e7adb !important;
}

.table-pro4tech-header-cell{
  color: #e4e4e4 !important;
  border-color: #0e7adb !important;
}

.Mui-active, .MuiTableSortLabel-iconDirectionAsc, .MuiTableSortLabel-iconDirectionDesc {
  color: #c7d3ee !important;
}

.table-pro4tech-header-cell .MuiTableSortLabel-root:hover {
  color: #a3a8c3 !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin-top: 10px !important;
}

.tabela-pro4tech.MuiPaper-root {
  background-color: #171b2600 !important;
}
/*Fim css table */

.input-suffix-pro4tech {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-suffix-pro4tech::after {
  content: '@pro4tech.com.br';
  width: 11em;
  height: 1.5em;
  position: absolute;
  top: 50%;
  right: 20px;  
  transform: translateY(-50%);
  opacity: 0.4;
  font-weight: bold;
  font-size: 16px;
}