.login-graph {
  --login-signed-out-button-background: rgb(40, 40, 40);
  --login-signed-out-button-hover-background: rgb(78, 78, 78);
  --login-signed-out-button-text-color: rgb(231, 231, 231);
}

.login-graph-div {
  background-color: rgb(40, 40, 40);
  color: rgb(188, 188, 188);
}

.login-graph-div:hover {
  background-color: rgb(78, 78, 78);
}

.min-h-280px {
  min-height: 280px;
}

.bg-accordion {
  background-color: #ff8176;
  color: white;
}

.bg-accordion:not(.collapsed) {
  background-color: #e0685d;
  color: white;
}

.accordion-button.collapsed::after,
.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.max-h-680px {
  max-height: 500px;
  overflow-y: auto;
}

.css-wsp0cs-MultiValueGeneric {
  padding: 0px !important;
  padding-left: 6px !important;
}