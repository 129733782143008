

.layout-background {
  background: linear-gradient(#a0a0b9, #1e1e2d);
}

[data-bs-theme="light"] .layout-login-background {
  background: rgba(255, 255, 255, 0.9) !important
}

[data-bs-theme="dark"] .layout-login-background {
  background: rgba(30, 30, 45, 0.85) !important
}
