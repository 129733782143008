.search-container {
    margin-left: auto; /* Garante que o campo fique no extremo direito */
  }
  
.custom-modal-size{
    max-width: 1000px; /* Ajuste para o tamanho desejado */
    margin: auto;
  }

  
@media (max-width: 768px) {
  .search-container {
    margin-left: auto; /* Garante que o campo fique no extremo direito */
    margin-top: 1rem;
  }
}