.chat-app {
    display: flex;
    height: calc(100vh - 99.36px);
    font-family: Arial, sans-serif;
}

@media(min-width: 992px) {
    .chat-app {
        height: calc(100vh - 130px) !important;
    }

    .chat-window.closed{
        height: calc(100vh - 130px) !important;
    }

    .chat-window {
        height: calc(100vh - 130px) !important;
    }
}

.sidebar {
    background-color: transparent;
    width: 250px;
    transition: width 0.3s;
    overflow: hidden;
}

.sidebar-list {
    overflow-y: auto;
    max-height: 90%
}

.sidebar.open-white {
    background-color: #dddddd;
    border-right: 2px solid #ccc; /* Borda na direita */
    border-bottom: 2px solid #ccc; /* Borda embaixo */
    border-radius: 0 0 10px 0px; /* Arredonda os cantos inferiores */
    height: auto;
}

.sidebar.open {
    background-color: #1a1a27;
    border-right: 2px solid #ccc; /* Borda na direita */
    border-bottom: 2px solid #ccc; /* Borda embaixo */
    border-radius: 0 0 10px 0px; /* Arredonda os cantos inferiores */
    height: auto;
}

@media (max-width: 768px) {
    .sidebar.open {
        width: 100vw;
        height: 100vh; /* Opcional, se quiser ocupar toda a altura também */
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000; /* Garante que fique sobre outros elementos */
    }

    .sidebar.open-white {
        width: 100vw;
        height: 100vh; /* Opcional, se quiser ocupar toda a altura também */
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000; /* Garante que fique sobre outros elementos */
    }

    .chat-title {
        font-size: 16px;
        font-weight: bold;
        max-width: 100% !important; /* Defina um tamanho máximo para o título */
        white-space: nowrap;
        overflow: hidden;
        position: relative;
    }
}


.sidebar.closed {
    width: 50px;
}

.sidebar.closed-white {
    width: 50px;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.toggle-btn {
    background: none;
    color: var(--bs-heading-color, inherit);
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 10px;
}

/* Estilo para cada item da lista */
.chat-item {
    padding: 10px;
    box-sizing: border-box; /* Garante que padding/margin respeitem o tamanho */
    overflow: hidden; /* Evita que textos longos quebrem o layout */
    text-overflow: ellipsis; /* Adiciona reticências para textos longos */
    white-space: nowrap; /* Evita que o texto quebre linhas */
    border-radius: 10px;
}
  
  .chat-item.active {
    background-color: #f0f0f0; /* Estilo para o item ativo */
  }
  
  .chat-item:hover {
    background-color: #eaeaea;
  }
  
  /* Conteúdo interno do item */
  .chat-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden; /* Garante que os conteúdos internos respeitem o tamanho */
}
  
  /* Parte esquerda (título + timestamp) */
.chat-item-left {
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }
  
.chat-title-input {
    font-size: 14px; /* Aumenta a fonte para melhor leitura */
    width: 100%; /* Ajusta automaticamente ao conteúdo */
    max-width: 150px; /* Permite um pouco mais de espaço */
    padding: 5px 8px; /* Adiciona espaço interno */
    border: 1px solid #ccc; /* Borda discreta */
    border-radius: 5px; /* Deixa os cantos arredondados */
    outline: none; /* Remove a borda azul padrão */
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
}

/* Efeito ao focar */
.chat-title-input:focus {
    border-color: #007bff; /* Azul para destacar */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Brilho sutil */
}

.chat-title {
    font-size: 16px;
    font-weight: bold;
    max-width: 100%; /* Defina um tamanho adequado */
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.chat-title::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30px; /* Largura do efeito de fade */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #1a1a27);
}

/* Quando o item está selecionado */
.chat-item.active .chat-title::after {
    background: linear-gradient(to right, rgba(240, 240, 240, 0), #9b9b9b); /* Ajuste para a cor de fundo do item ativo */
}

/* Quando o item está no hover */
.chat-item:hover .chat-title::after {
    background: linear-gradient(to right, rgba(234, 234, 234, 0), #444444); /* Ajuste para a cor de fundo do hover */
}

.chat-title-white {
    font-size: 16px;
    font-weight: bold;
    max-width: 150px; /* Defina um tamanho máximo para o título */
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.chat-title-white::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30px; /* Largura do efeito de fade */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #dddddd);
}

/* Quando o item está selecionado */
.chat-item.active .chat-title-white::after {
    background: linear-gradient(to right, rgba(240, 240, 240, 0), #9b9b9b); /* Ajuste para a cor de fundo do item ativo */
}

/* Quando o item está no hover */
.chat-item:hover .chat-title-white::after {
    background: linear-gradient(to right, rgba(234, 234, 234, 0), #444444); /* Ajuste para a cor de fundo do hover */
}
  
.chat-timestamp {
    font-size: 12px;
    color: #888;
  }
  
  /* Botão no lado direito */
.chat-item-button {
    background-color: transparent;
    color: var(--bs-heading-color, inherit);
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
.chat-item-button:hover {
    background-color: #0056b3;
  }
  
  /* Linha separadora */
.linha-horizontal {
    width: 90%;
    height: 1px;
    background-color: #ccc;
    margin: 5px auto;
  }  

.chat-history-white li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.chat-history-white li:hover {
    background-color: #444;
}

.chat-history-white .active {
    background-color: #9b9b9b;
}

.chat-history li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.chat-history li:hover {
    background-color: #444;
}

.chat-history .active {
    background-color: #9b9b9b;
}

.chat-window {
    display: flex;
    flex-direction: column;
    height: 83vh;  /* Garante que ocupe toda a altura da tela */
    width: 80%;
    justify-content: center;
    align-items: center;
}

.chat-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;  /* Isso faz a área de conteúdo crescer para ocupar o máximo de espaço */
    overflow-y: auto;
    width: 80%;
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    overflow-y: auto;
    flex-grow: 1;
}

.message {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    margin: 0;
    word-wrap: break-word;  /* Permite a quebra de linha quando o texto é longo */
    white-space: normal;    /* Faz com que o conteúdo de texto se quebre */
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

.user-container {
    align-items: flex-end !important;
}

.message-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.avatar-user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
}

.sender-name {
    font-weight: bold;
    margin-right: 5px;
}


.sender-name-user {
    font-weight: bold;
    margin-left: 5px;
}

.timestamp {
    font-size: 12px;
    color: gray;
}

.timestamp-user {
    font-size: 12px;
    color: gray;
}

.user-message {
    background-color: #575757;
    color: white;
    align-self: flex-end; /* Mensagem do usuário alinhada à direita */
}

.chat-message {
    background-color: #ddd;
    color: black;
    align-self: flex-start; /* Mensagem do chat alinhada à esquerda */
}

.message-input {
    position: relative; /* Define o contêiner como relativo para posicionamento do botão */
    width: 100%;
    margin-top: 10px;
    padding: 0; /* Remove padding externo desnecessário */
}

.message-input input {
    width: 100%;
    padding: 10px 50px 10px 10px; /* Espaço para o botão no lado direito */
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box; /* Garante que padding não afete o tamanho */
}

.message-input button {
    position: absolute; /* Coloca o botão dentro do contêiner */
    top: 50%; /* Centraliza verticalmente */
    right: 10px; /* Alinha à direita dentro do input */
    transform: translateY(-50%); /* Ajusta para o centro exato */
    background-color: white;
    color: #222220;
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.bi-arrow-bar-up {
    font-size: 20px; /* Ajuste o tamanho conforme necessário */
    font-weight: bold; /* Torna o ícone mais "grosso" */
    color: #222220; /* Cor do ícone */
}

.chat-placeholder {
    text-align: center;
    color: #888;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message-input.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.message-input.centered input {
    margin-bottom: 10px;
    width: 100%;
    border-radius: 5px;
}


body.chat-page #kt_app_content_container {
    padding: 0 !important;
}


/* Estilo da seção principal */
.area-section {
    padding: 16px;
    background-color: #1a1a27;
    overflow-y: auto;
}

.area-section-white {
    padding: 16px;
    background-color: #dddddd;
    overflow-y: auto;
}

/* Nome da área */
.area-name {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin: 8px 0;
    padding: 8px;
    border-radius: 4px;
    background-color: #1a1a27;
    transition: background-color 0.3s;
}

/* Nome da área */
.area-name-white {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin: 8px 0;
    padding: 8px;
    border-radius: 4px;
    background-color: #ffffff;
}

.area-name:hover {
    background-color: #444;
}

.subarea-list {
    margin-left: 16px;
    border-left: 2px solid #ddd;
    padding-left: 12px;
}

.subarea-list-white {
    margin-left: 16px;
    border-left: 2px solid #ddd;
    padding-left: 12px;
}

.subarea-name {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin: 6px 0;
    padding: 6px;
    border-radius: 4px;
    background-color: #1a1a27;
}

.subarea-name-white {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin: 6px 0;
    padding: 6px;
    border-radius: 4px;
    background-color: #ffffff;
}

.subarea-name:hover {
    background-color: #444;
}

.area-name.selected,
.subarea-name.selected {
    background-color: #9b9b9b;
    font-weight: bold; /* Texto em negrito */
    border: 1px solid #666; /* Borda opcional */
}

.area-name-white.selected,
.subarea-name-white.selected {
    background-color: #9b9b9b;
    font-weight: bold; /* Texto em negrito */
    border: 1px solid #666; /* Borda opcional */
}

.selected-subarea {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


/* Estilo responsivo */
@media (max-width: 768px) {
    .area-section {
        padding: 12px;
        font-size: 14px;
    }

    .area-name, .subarea-name {
        font-size: 16px;
        padding: 6px;
    }
}

.loading-dots {
    display: flex;
    justify-content: start;
    margin: 1rem 0;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #9b9b9b;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10px);
    }
}

/*RECORDING*/
.recording-indicator-container {
    display: flex;
    align-items: center;
}

.recording-indicator {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: red;
    animation: fade-in-out 1.5s infinite;
}

.recording-dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
}

@keyframes fade-in-out {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}